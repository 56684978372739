/*
 * Author: Carl Johansson @carlwebdev
 * Project: cowo-Reset
 * File: Layout
 */



/*
 * CODE FOR RESPONSIVE STICKY FOOTER
 * Instructions: class "block" to footer
 */
html, body {
	height: 100%;
	}
#page {
	// display: table;
	height: 100%;
	width:100%; /* VIP for responsiveness */
	}
.stickyFooter { // WAS block
	/*
	display: table-row;
	height: 1px;
	*/
	}





body {
	background-color: $c_white;
	// background-color: red; 
  }
	#page {
		/* PLACEHOLDER */
		}
		header {
			background-color: $c_white; // TEMP
			/*
			background-color: red; 
			border: 2px solid blue;
			*/
			position: relative;
			z-index: 1;
			}
		footer {
			background-color: $c_bg_gray; // TEMP
			}
			
			
/* WIDTHS */	



	.frame_max { 
		@include centered;
		// max-width:1680px; // same as "frame" but without horizntal padding
		max-width:1240px;
		} // frame_m 	
		
		
		
			
.frame { 
  @include centered;
  // max-width:1440px;
  // max-width:1140px;
	// max-width:1680px;
	max-width:1240px;
  min-width:320px; 
  @include paddingh($spacing);	
	
	// CAN BE SIMPLIFIED AS GENRIC TRANSIITON VALUE MIXIN
  @include transition(padding-left, $t_slow); // RWD EYE-CANDY
  @include transition(padding-right, $t_slow); // RWD EYE-CANDY
	
  @media only screen and (max-width: $bp_m) {
		// @include paddingh($spacing05);
		@include paddingh($spacing075);
		} // MEDIA-QUERY	
		
  } // frame 			
	
.frame_header { // could be recycled, into frame same width as main frame but without any padding at all
  @include centered;
  // max-width:1440px;
  // max-width:1140px;
	// max-width:1680px;
	// max-width:1440px;
	max-width:1240px;
  min-width:320px; 
  /*
	@include paddingh($spacing);	
	
	// CAN BE SIMPLIFIED AS GENRIC TRANSIITON VALUE MIXIN
  @include transition(padding-left, $t_slow); // RWD EYE-CANDY
  @include transition(padding-right, $t_slow); // RWD EYE-CANDY
	
  @media only screen and (max-width: $bp_m) {
		// @include paddingh($spacing05);
		@include paddingh($spacing075);
		} // MEDIA-QUERY	
	*/
  } // frame 	
	
	
		
.frame_to_flush { // used in sectors list boxes, for no padidng when ipad etc... / also used in sector random image 
  @include centered;
  // max-width:1440px;
  // max-width:1140px;
	max-width:1680px;
  min-width:320px; 
  @include paddingh($spacing);	
	
	// CAN BE SIMPLIFIED AS GENRIC TRANSIITON VALUE MIXIN
  @include transition(padding-left, $t_slow); // RWD EYE-CANDY
  @include transition(padding-right, $t_slow); // RWD EYE-CANDY
	
  @media only screen and (max-width: $bp_l) {
		@include paddingh(0px);
		} // MEDIA-QUERY	
		
  } // frame_to_flush 		
	
	
	
	
	

	.frame_m { 
		@include centered;
		max-width:1024px;
		} // frame_m 	
	






// in php start / end - really unnnecessary .... and should be class anywasy...
#content { // 
	// min-height:200px; 
	overflow:hidden; // ????????? 
	// background-color: #999;
	@include paddingv($spacing4);
	
	.inner {} // too unspecific, as there is a frame in between... but could be a different frame... 
	
	
	} // content








section {
	
	// ASA CLASS MODIFIER, ELSE IT'S TOO GENERAL.
	// background-color: #CCC; 
	// margin-bottom: $spacing2; // TEST
	
	// border: 1px solid black; 
	
	
	} // section

