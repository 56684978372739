/*
 * Author: Carl Johansson @carlwebdev
 * Project: cowo-Boilerplate
 * File: Page Home
 */
 
 
 
section.fullPageImage { // USED IN: Homepage, ...
 // border: 10px solid red; // DEV
	
	// BG IMG
	@include bg_img;

	// height: 100vh; // TEMP
	height: 62vh; // TEMP - GOLDEN
	
	// height: 50%; // tendra la parte de raroiba cortada por el header sticky 
	// height: 200px;
	position: relative;



	// HOMEPAGE
	.textBoxHome {
		// border: 1px solid red;
		
		position: absolute; 
		bottom: $spacing2; // TEST
		
		left: 20%; // TEST
		
		color: $c_white;
		text-shadow: 0px 0px 2px #000000;
		
		
		// font-size: 6px;
		
		.line1 {
			font-size: $fs_l;
			font-size: 32px;
			
			// font-size: 5rem; // TEST FOR RWD EASIER
			
			
			// margin-bottom: $spacing; // TEST
			margin-bottom: $spacing05; // TEST
			
			margin-left: $spacing2; 
			margin-left: 175px;
			font-style: italic;
			} // line1
		
		.line2 {
			text-transform: uppercase; // PRECAUTION
			// font-size: $fs_xxl;
			// font-size: 64px;
			font-size: 72px;
			} // line2
			
		.line3 {
			text-transform: uppercase; // PRECAUTION
			// font-size: $fs_xl;
			font-size: 48px;
			margin-bottom: $spacing; // TEST
			
			margin-left: $spacing3; 
			margin-left: 200px;
			font-style: italic;
			} // line3
			
		.line4 {
			text-transform: uppercase; // PRECAUTION
			// font-size: $fs_xl;
			font-size: 24px;
			margin-left: $spacing4; 
			margin-left: 250px; 
			font-style: italic;
			} // line4
			
			
			
		@media only screen and (max-width: $bp_xl) { 
			left: 15%; // TEST
			} // MEDIA-QUERY
						
		@media only screen and (max-width: $bp_l) { 
			// left: 20%; // TEST
			// left: 10%; // TEST
			left: 5%; // TEST
			
			.line1 {

				} // line1
			
			.line2 {

				} // line2
				
			.line3 {

				
				} // line3
				
			.line4 {

				} // line4

			
			} // MEDIA-QUERY	
			
				
		@media only screen and (max-width: $bp_m) { 
			
			// left: 5%; // TEST
			
			.line1 {
				margin-left: 0px;
				} // line1
			
			.line2 {

				} // line2
				
			.line3 {
				margin-left: 0px;
				
				} // line3
				
			.line4 {
				margin-left: 0px;
				} // line4

			
			} // MEDIA-QUERY	
			
			
			
			
			
		@media only screen and (max-width: $bp_s) { 
			
			
			// left: 5%; // TEST
			
			.line1 {

				} // line1
			
			.line2 {

				} // line2
				
			.line3 {

				
				} // line3
				
			.line4 {

				} // line4

			
			} // MEDIA-QUERY	
			
			
						
												
		} // textBoxHome






	// 
	// SECTOR PAGES USE THSI MODULE, WITH BOX INSIDE - & ALSO USED BY SEVICES PAGE(S)
	// 
	
	// NEW
	.w_textBox {
		// border: 3px solid orange; // dev
		
		// has dynamnic bg color - not
		
		// and frame inside, fram standrad or frame header etc
		
		
		position: absolute; 
		bottom: $spacing2; // TEST
		width: 100%;
		
		@media only screen and (max-width: $bp_l) { 
			bottom: 0px; // TEST
			} // MEDIA-QUERY	
			
			
		@extend .clearfix;
				
		} // w_textBox
	
	
	
	
	.textBox {
		/*
		position: absolute; 
		bottom: $spacing2; // TEST
		*/
		
		// border: 3px solid red; // dev
		
		
		
		/* WORDPRESS - ACF */
		&.left {}
		&.right {
			// right: 0px;
			float: right;
			} // THE ALTERNATE SCENARIO
		
		
		// padding: $spacing2; // test
		// padding: $spacing3; // test
		padding: 60px; // test
		// width: 50%;
		// width: 38%;
		width: 50%;
		@include transition(all $t_fast linear); // width, left, right, bottom
		
		// border: 3px solid black; // DEV
		
		@media only screen and (max-width: $bp_xl) { 
			width: 62%;
			// width: 50%;
			} // MEDIA-QUERY		
				
				
		@media only screen and (max-width: $bp_l) { 
			// width: 62%;
			/*
			width: calc(100% - 96px); // TEST
			left: $spacing2; // TEST
			right: $spacing2; // TEST
			*/			
			width:100%;
			} // MEDIA-QUERY	
			
							
		@media only screen and (max-width: $bp_m) { 
			@include fullwidth; 
			/*
			bottom: 0px; // VS PADDING ALL OVER OUTSEIDE... 
			left: 0px;  // TEST
			right: 0px;  // TEST
			*/
			} // MEDIA-QUERY	
		
		@media only screen and (max-width: $bp_s) { 
			// @include fullwidth; 
			/*
			bottom: 0px; // VS PADDING ALL OVER OUTSEIDE... 
			left: 0px;  // TEST
			right: 0px;  // TEST
			*/
			} // MEDIA-QUERY	
		
		
		
		// CLASS MODIFIER
		&.bg_blue {
			// background-color: #264E89; // COPIED FOR BLUE, DE SECTORS GRID DE L AHOME..
			background-color: rgba(#264E89, 0.9); // DESIGN - I NEED THE BASE COLOR BEFORE THE OPACITY IS APPLIED !!!
			}
		
		/*
		&.bg_blue {
			// background-color: #264E89; // COPIED FOR BLUE, DE SECTORS GRID DE L AHOME..
			background-color: rgba(#264E89, 0.9); // DESIGN - I NEED THE BASE COLOR BEFORE THE OPACITY IS APPLIED !!!
			}
		*/	
					
		color: $c_white;

		.title {
			// font-size: $fs_xl;
			font-size: $fs_xxl;
			text-transform: uppercase; // PRECAUTION (NOT REALLY)
			@include f_main_bold;
			margin-bottom: $spacing05; // TEST
			}
			
		.text	{
			// font-size: $fs_l; // TEST
			font-size: $fs_xl;
			} // text
		
		} // textBox



	} // fullPageImage 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
section.bigIntroText { // USED IN: Homepage, ...
	// border: 1px solid red; // DEV
	
	// @include paddingv($spacing2); // TEST
	@include paddingv($spacing3); // TEST
	padding-top: $spacing4;
	text-align: center;
	font-size: $fs_xl; // TEST, SAME AS NAV.. 
	} // bigIntroText
	
	
section.ctaText { // USED IN: Homepage, ...
	@include paddingv($spacing2); // TEST - NOW MOVED TO ANCHOR 
	text-align: center;
	font-size: $fs_l; // TEST, SAME AS NAV.. 
	
	// change the font ... 
	
	// ITALYC, BOLDISH, AND GREYISH ... & uppercase...
	text-transform: uppercase; // PRECAUTION
	@include f_main_bold;
	font-style: italic;
	color: #626365; // EXTRACTED FROM PDF
	
	
	&.case-link { // HOMEAPFGE - IT'S A BIG LINK TO SREVICES PAGE
		@include paddingv(0px); // RESET
		
		a {
			color: inherit; // SHPULD BE IN THE RESET FILE
			display: block;
			@include paddingv($spacing2); // TEST
			&:hover {text-decoration: underline;}
			} // a
	
		} // case-link
	
	
	} // ctaText
	
	
		
		
		
		
/* PAGE: HOME, ...
 * GRID: SECTORS 
 */		
		
section.w_gridSectors { // USED IN: Homepage, ...
	// border: 10px solid green; 
	} // ctaText
	
.gridSectors {
	// overflow: hidden;
	@extend .clearfix; // TEST // WORKS
	
	.col {
		float: left;
		width: 20%;
		// border: 3px solid red; 
		
		// RWD
		@media only screen and (max-width: 1240px) { // was $bp_l
			width: calc(100% / 3);
			} // MEDIA-QUERY					
		@media only screen and (max-width: $bp_m) { 
			width: 50%;
			} // MEDIA-QUERY			
		@media only screen and (max-width: $bp_s) { 
			@include fullwidth; 
			} // MEDIA-QUERY	
		
		} // col
	
	.item {
		
		// JQUERY
		&.is-active {
			// DO SOMETHING
			.image .imageCover {
				// display: block;
				background-color: rgba(#fff, 0.3);
				}
				
			.info .arrow .anchor .layer {display: none;}
			
			} // is-active
			
					
							
		.image {
			// BG IMG
			@include bg_img;
			@include ar_4_3; // TEST
			
			// img {@include img;}
			
			position: relative;
			.imageCover {
				// display: none; // SHOW WITH JQUERY
				background-color: rgba(#fff, 0.0);
				// background-color: rgba(#fff, 0.5);
				position: absolute; top: 0px;
				width: 100%;
				height: 100%;
				} // imageCover
			
			} // image				
		
		.info {
			color: $c_white;
			/*
			&.bg_blue {background-color: #264E89;}
			&.bg_orange {background-color: #FF7900;}
			&.bg_red {background-color: #DB343C;}
			&.bg_green {background-color: #C1BB00;}
			&.bg_marine {background-color: #007EA3;}												
			*/
			
			@include paddingv($spacing2); // TEST
			@include paddingh($spacing075); // TEST

			padding-bottom: $spacing2; // TEST
			// border: 1px solid red; // 
			@extend .clearfix;
			
			.line1 {
				font-style: italic; // ITALIC - OK
				font-size: $fs_s;
				} // line1
				
			.line2 {
				text-transform: uppercase; // PRECAUTION
				@include f_main_bold;
				font-size: $fs_xl;
				} // line2	
				
			.arrow { 
				
				// font-size: $fs_xl;
				font-size: 80px; // TEST
				line-height: 0.2em; // TEST
				/*
				&.is-active {
					// DO SOMETHING
					border: 3px solid red; // DEV
					} // is-active
				*/

				
				// a,
				.anchor {
					display: block;
					margin-top: $spacing; // TEST
					// margin-bottom: $spacing2; // TEST
					
					float: right;
					margin-right: $spacing2; // TEST
					// width: 100px;
					// border-bottom: 2px solid white;
					color: $c_white;
					// background-color: #ccc; // dev
					
					position: relative;
					
					
					.actual { // relative to the a, or anchor..
	
						} // 
						
										
					.layer { // relative to the a, or anchor..
						// border: 1px solid red; 
						width: 23px;
						height: 30px;
						position: absolute; 
						right: 0px;
						top: 2px;
						} // 
					
					} // a
			
				
				
				
					
					
								
				} // arrow	
			
			
			} // info
			
			
		} // item
		
		
	}	// gridSectors
	
	
	
	
/* PAGE: HOME, ...
 * GRID: SERVICES 
 */	
	
section.w_gridServices { // USED IN: Homepage, ...
	// border: 10px solid green; 
	} // ctaText
	
.gridServices  {
	@extend .clearfix; // TEST // WORKS
	
	.col {
		float: left;
		width: 50%;
		@media only screen and (max-width: $bp_s) {@include fullwidth;} // MEDIA-QUERY	
		} // col
	
	.item {
		
		
		// JQUERY
		&.is-active {
			// DO SOMETHING
			.image .imageCover {
				// display: block;
				background-color: rgba(#fff, 0.3);
				}
				
			.info .arrow .anchor .layer {display: none;}
			
			} // is-active
			
			
					
		.image {
			// BG IMG
			@include bg_img;
			@include ar_cinemascope; // TEST
			// img {@include img;}
			
			position: relative;
			.imageCover {
				// display: none; // SHOW WITH JQUERY
				background-color: rgba(#fff, 0.0);
				// background-color: rgba(#fff, 0.5);
				position: absolute; top: 0px;
				width: 100%;
				height: 100%;
				} // imageCover
							
			} // image		
		
		// text color !!! select de black /default) vs white !!!.
		
		.info {
			// color: $c_white;
			// numbers indicate increasing darkness
			/*			
			&.bg_1 { // bg_white
				background-color: #FFFFFF;
				color: $c_black;
				}
			&.bg_3 { // bg light gray
				background-color: #9A9B9C;
				}
			&.bg_4 { // bg dark grey
				background-color: #616365;
				color: $c_white; // ????
				}
			&.bg_2 { // bg ocre
				background-color: #E9E1DD;
				}											
			*/
			
			@include paddingv($spacing2); // TEST
			// @include paddingh($spacing075); // TEST
			// @include paddingh(36px); // TEST // EL DOBLE QUE EL gridSectors
			@include paddingh($spacing2); // TEST
			
			
			//padding-bottom: $spacing3; //  
			@extend .clearfix;
			
			.line1 {
				font-size: $fs_s;
				font-style: italic; // ITALIC !!!!// TEST !!!!!!!!!!	// OK		
				} // line1
				
			.line2 {
				text-transform: uppercase; // PRECAUTION
				font-size: $fs_xl;				
				@include f_main_bold;
				margin-bottom: $spacing05; // TEST
				} // line2	
				
			.line3 {
				// font-size: $fs_l; // TEST
				} // line2	
				
			.arrow { 
				
				// font-size: $fs_xl;
				font-size: 80px; // TEST
				line-height: 0.2em; // TEST
				/*
				&.is-active {
					// DO SOMETHING
					border: 3px solid red; // DEV
					} // is-active
				*/

				// a,
				.anchor {
					display: block;
					margin-top: $spacing; // TEST
					// margin-bottom: $spacing2; // TEST
					
					float: right;
					margin-right: $spacing2; // TEST
					// width: 100px;
					// border-bottom: 2px solid white;
					color: $c_white;
					// background-color: #ccc; // dev
					
					position: relative;
					
					.actual { // relative to the a, or anchor..
	
						} // 
										
					.layer { // relative to the a, or anchor..
						// border: 1px solid red; 
						width: 23px;
						height: 30px;
						position: absolute; 
						right: 0px;
						top: 2px;
						} // 
					
					} // a
			
								
				} // arrow	
			
			
			} // info
			
			
		} // item
		
		
		@media only screen and (max-width: $bp_s) {
			
			.col {@include fullwidth;}
		
			} // MEDIA-QUERY	
				
		
		@media only screen and (max-width: $bp_xs) {
			
		
			.item {
	
				.info {
						// @include paddingv($spacing2); // TEST
						@include paddingv($spacing); // TEST
						
						
						// @include paddingh($spacing075); // TEST
						// @include paddingh(36px); // TEST // EL DOBLE QUE EL gridSectors
						
						// @include paddingh($spacing2); // TEST
						@include paddingh($spacing); // TEST
						
						
						
					.arrow { 
					
						a {
							// margin-right: 25%; // TEST
							margin-right: 0%; // TEST
							} // a
										
					
						} // arrow	
				
				
										
						} // info

					} // item
		
		
			} // MEDIA-QUERY	
			
					
	}	// gridServices
	
	
	
		
		
		







/* PAGE: HOME, ...
 * GRID: ADDRESSES 
 */	
	
section.w_gridAddresses { // USED IN: Homepage, ...
	// border: 10px solid green; 
	} // ctaText
	
	
.header_gridAddresses { 
	// @include paddingv($spacing2); // TEST	
	@include paddingv($spacing3); // TEST
	padding-top: $spacing4; // TEST
	text-align: center;
	.line1 {
		font-size: $fs_xl;
		margin-bottom: $spacing05; // TEST
		@include f_main_bold;
		} // line1
		
	.line2 {
		font-size: $fs_l; // TEST !!!!!!
		} // line2	
	
	} // header_gridAddresses


	
.gridAddresses  {
	padding-bottom: $spacing4; // TEST
	
	
	@include paddingh($spacing2); // TEST
	@media only screen and (max-width: $bp_l) { 
		@include paddingh(0px); // TEST 
		} // MEDIA-QUERY	
		
	
	@media only screen and (max-width: $bp_s) { 
		// @include paddingh($spacing2); // TEST
		padding-bottom: $spacing2; // TEST
		
		} // MEDIA-QUERY	
		
		
	// overflow: hidden;
	/* @extend .clearfix; // TEST // WORKS */


	// TURN INTO ALIGNED JUSTIFY SCENARIO. TEST 1ST WITH FULLWIDTH DEFAULKT AS IS PER SE WTF.

	// CSS TRICKS - https://css-tricks.com/equidistant-objects-with-css/
	text-align: justify;
	&:after {
		content: '';
		width: 100%; /* Ensures there are at least 2 lines of text, so justification works */
		display: inline-block;
		}	
			
			
	// TEMP TEST
	@extend .clearfix;
			
	.col {
		/*
		float: left;
		width: 20%;
		*/
		
		// CSS TRICKS - https://css-tricks.com/equidistant-objects-with-css/
		display: inline-block;
		vertical-align: top; // TEST
		text-align: left; // RESET - TEST
		
		margin-bottom: $spacing; // TEST - for that brief momnt that the 5th one jumps down to the next row
				
		// RWD	
		
		@media only screen and (max-width: $bp_l) { 
		
			display: block; // TEST 3RD AGAIN
			float: left;
			width: 32%; 
			margin-right: 2%;
			&:nth-of-type(3n) {margin-right: 0%;}
			
			margin-bottom: $spacing; // TEST
			&:last-of-type {margin-bottom: 0%;} // PRECAUTION
			
			} // MEDIA-QUERY	
			
			
					
		@media only screen and (max-width: $bp_m) { 
			width: 49%;
			&:nth-of-type(3n) {margin-right: 2%;} // RESET
			&:nth-of-type(2n) {margin-right: 0%;}
			} // MEDIA-QUERY	
			
					
		@media only screen and (max-width: $bp_s) { 
			@include fullwidth; 
			margin-right: 0%;
			} // MEDIA-QUERY	
		
		} // col
	
	.item {
		
		.info {
			// @include paddingh($spacing075); // TEST
			// @include paddingh(36px); // TEST // EL DOBLE QUE EL gridSectors
			
			// @include paddingh($spacing2); // TEST - WORKS KINDA OK LIKE THIS, AS IT IS.. FOR NOW..
			
			.line1 {
				// font-size: $fs_m;
				font-size: $fs_l;
				@include f_main_bold;
				// margin-bottom: $spacing05; // TEST
				// margin-bottom: $spacing025; // TEST
				} // line1
				
			.line2 {
				// font-size: $fs_s;
				font-size: $fs_m;
				line-height: 1.6em; // TEST
				} // line2	

			a {
				// color: inherit; // BASE REST EFILE !!!
				&:hover {text-decoration:underline;} // maybe not enclose the work "Mail" i the fort... 
				} // a
			
			} // info
			
		} // item
		
		
	}	// gridAddresses
	
	
	
	
			
		
		
		