/*
 * Author: Carl Johansson @carlwebdev
 * Project: cowo-Boilerplate
 * File: Page Home
 */
 
 
/* PAGE: SERVICES
 * MODULE: INTRO TEXT  -SECTOR INTRO TEXT 
 */		
 
section.w_gridReferencesPage { // USED IN: Homepage, ...
	// border: 10px solid green; 
	} // w_gridServicesPage
	
	
	
	
	
	
	
	
	// grey box here !!!...........
	
	
section.w_grayTextBox { // USED IN: References(main) aka list, for now ...
	// border: 10px solid green; 
	
	background-color: #A8A9AB; // TEMP, EXTRACTED FORM FILE  -SHOULD BE SASSIFIED IF PSSIBEL..
	padding: $spacing; // TEST
	
	.info {
		width: 50%; // TEST + RWD !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
		
		.title { // SAME AS IN INTRO TEXT THINGE - COPIED FORM THERE, CREAT SASS MIXING !!!!!!!!!!!!!!!!!!
			text-transform: uppercase; // PRECAUTION
			// font-size: $fs_l; // TEST
			font-size: $fs_xl; // TEST - BECAUSE IT'S NOT QUITE THE SAME !!!
			font-weight: bold; // TEMP WHLE I ADD THE FONT !!!
			margin-bottom: $spacing; // TEST - same separation que entre p's
			}
		
		.text { // SHOULD IT ALS O BE COLUMNS !?!?!?!
			// font-size: $fs_m; // TEST
			font-size: $fs_l; // TEST
			
			
			// border: 1px solid red; // DEV
			}
	
		} // info
	
	} // w_grayTextBox
	
	
		
	
	
	
.gridReferencesPage {
	padding-top: 36px; // TEST
	padding-bottom: $spacing4; // TEST

	.gridReferencesPage_section {
		// margin-bottom: $spacing; // TEST
		// margin-bottom: $spacing2; // TEST
		margin-bottom: 36px; // TEST // SAME DISTANCE AS FORM THE TOP
		&:last-of-type {margin-bottom: 0%;}
		
		.top {
			// INCLUDES title AND link
			@extend .clearfix;
			// margin-bottom: $spacing; // TEST
			margin-bottom: $spacing05; // TEST
			} // top
			
			.title {
				float: left;
				text-transform: uppercase; // PRECAUTION
				font-size: $fs_l; // TEST
				@include f_main_bold;
				} // title
			
			.link {
				float: right;
				padding-top: 6px; // TEST
				a {
					text-decoration: underline;
					&:hover {text-decoration: none;}
					} // a
								
				} // link
		
		} // gridReferencesPage_section
		
		
		.row {@extend .clearfix;}
	
		.col {
			float: left;
			width: 32%;
			margin-right: 2%;
			&:nth-of-type(3n) {margin-right: 0%;}
			@media only screen and (max-width: $bp_m) { 
				width: 49%;
				&:nth-of-type(3n) {margin-right: 2%;} // RESET
				&:nth-of-type(2n) {margin-right: 0%;}
				
				/*
				margin-bottom: 2%;
				&:last-of-type {margin-bottom: 0%;}
				*/
				// TRICK
				&:nth-of-type(3) {display:none;}
				} // MEDIA-QUERY	
						
			@media only screen and (max-width: $bp_s) { 
				@include fullwidth; 
				// TRICK
				&:nth-of-type(2) {display:none;}				
				} // MEDIA-QUERY	
		
			} // col
			
			
				
	.item {
		// bg-color FROM DATABASE

		.image {
			// BG IMG
			@include bg_img;
			@include ar_4_3; // TEST, creo que ok
			// img {@include img;}
			} // image				
		
		.info {		
			color: $c_white;
			@include f_main_bold;
			// @include paddingv($spacing2); // TEST
			@include paddingv($spacing); // TEST
			// @include paddingh($spacing2); // TEST
			@include paddingh($spacing); // TEST
			
			.line1 {
				// text-transform: uppercase; // PRECAUTION
				// font-size: $fs_l;
				// font-size: $fs_xl; // TEST
				// margin-bottom: $spacing; // TEST
				} // line1
				
			.line2 {
				// font-size: $fs_m;
				// font-size: $fs_l; // TEST
				margin-top: $spacing; // TEST HERE, IN CASE NO EXISTS
				} // line2	
				
			} // info
			
		
		@media only screen and (max-width: $bp_m) {
			
			.col {
				width: 49%;
				&:nth-of-type(3n) {margin-right: 2%;} // RESET
				&:nth-of-type(2n) {margin-right: 0%;}
				} // col
				
			.image {
				// BG IMG
				// @include ar_16_9; // TEST, PARA MENOS ALTURA, YA QUE LAS IMAGENES SON BULLSHIT ANYWAYS.
				// @include ar_cinemascope; // TEST
				} // image	
				
			.info {		

				} // info
							
			} // MEDIA-QUERY	
		
		
			
		@media only screen and (max-width: $bp_s) {
			
			.col {
				@include fullwidth;
				} // col
							
			.info {		

				} // info
							
			} // MEDIA-QUERY
			
				
		} // item
		
	}	// gridReferencesPage