/*
 * Author: Carl Johansson @carlwebdev
 * Project: cowo-Boilerplate
 * File: Buttons
 */






/* BELONGS HERE IN FORMS IN RESET FILE  / class="css_submit_button" */

/* USE css style for submit button (input) inseide generic_links */
.css_submit_button {cursor:pointer; border:none;
border:2px solid #000; 
padding:0.2rem 0.5rem;
background-color:#bdbec0; /* GRISACEO */
font-family: 'Antonio', sans-serif;
font-weight:bold;
font-size:1.25rem;
color:#000;
}
	.css_submit_button:hover {background-color:#000; color:#0d80a1;}

input[type="submit"].css_submit_button:disabled
{
background:#dddddd; color:#F00; cursor:default;
}









/* do sass for colors, and for interactiuon behaviours - UI */
/* do sass for interactiuon behaviours - UI */

// 3 SPEEDS !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!	

$t_fast: 0.2s;
$t_base: 0.6s;
$t_slow: 1.0s;	
	
	
	
	
@mixin transitionButton {
	@include transition(all $t_base linear);
	}
	
	



// .pointer {cursor: pointer;} /* THIS IS TOO GENERIC !!!!!!!!! */






/* UI ELEMENTS / move to buttons file ================================================================================== */



.buttonStyleX { // MAYEB ADD TRASNITION SPEED !!!
	background-color:$c_primary_dark;
	&:hover {background-color: $c_primary;}		
	}


	
	
	
.button {
	cursor:pointer;
	@include transitionButton;
	@extend .buttonStyleX;
	font-weight: bold;
	color:#fff;	
	
	/* LAYOUT RELATED - SHOULDN'T BE HERE !!! */
	float:left;
	clear:left;
	margin-bottom: $spacing;
	}

	.buttonSmall {
		@extend .button;
		font-size: $fs_s;
		padding: $spacing025;
		border-radius: $spacing0125;
		}
	.buttonMedium {
		@extend .button;
		font-size: $fs_m;
		padding: $spacing025 $spacing05;
		border-radius: $spacing025;
		}	
	.buttonBig {
		@extend .button;
		font-size: $fs_l;
		padding: $spacing05 $spacing;
		border-radius: $spacing025;
		}		