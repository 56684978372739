/*
 * Author: Carl Johansson @carlwebdev
 * Project: cowo-Boilerplate
 * File: Page Home
 */
 
 
/* PAGE: SERVICES
 * MODULE: INTRO TEXT  -SECTOR INTRO TEXT 
 */		
 
/* 
section.w_gridReferencesPage { // USED IN: Homepage, ...
	// border: 10px solid green; 
	} // w_gridServicesPage
*/	
	
	
	
	
	
	
	
	// grey box here !!!...........
	
	
section.w_NewsPage { // USED IN: References(main) aka list, for now ...
	// border: 10px solid green; 
	
	// background-color: #A8A9AB; // TEMP, EXTRACTED FORM FILE  -SHOULD BE SASSIFIED IF PSSIBEL..
	// padding: $spacing; // TEST
	// border: 1px solid black; // DEV TEMP


	margin-bottom: $spacing; // test

		// USE THE the_content FIELD FOR THE NAME SHOWN
		.title { // SAME AS IN INTRO TEXT THINGE - COPIED FORM THERE, CREAT SASS MIXING !!!!!!!!!!!!!!!!!!
			text-transform: uppercase; // PRECAUTION
			// font-size: $fs_l; // TEST
			font-size: $fs_xl; // TEST - BECAUSE IT'S NOT QUITE THE SAME !!!
			// font-weight: bold; // TEMP WHLE I ADD THE FONT !!!
			@include f_main_bold;
			// margin-bottom: $spacing; // TEST - same separation que entre p's
			}
		
	
	} // w_NewsPage
	
	
		
section.w_gridNewsPage { // USED IN: Homepage, ...
	// border: 10px solid green; 
	// margin-bottom: $spacing2; // TEST
	margin-bottom: $spacing4; // TEST
	} // w_gridNewsPage	
	
	
.gridNewsPage {
	// overflow: hidden;
	@extend .clearfix; // TEST // WORKS
	
	// border: 10px solid black; // DEV TEMP


		.col {
			float: left;
			width: 32%;
			// border: 3px solid blue; 
			margin-right: 2%; 
			&:nth-of-type(3n) {margin-right: 0%;}
			&:nth-of-type(3n+1) {clear: left;} // TEST
			
			margin-bottom: 2%; 
			&:last-of-type {margin-bottom: 0%;} // SIMPLE PRECAUTION FOR SINGLE COLUMN SCENARIO
			
			// RWD
			@media only screen and (max-width: $bp_m) { 
				width: 49%;
				&:nth-of-type(3n) {margin-right: 2%;}
				&:nth-of-type(3n+1) {clear: none;} // TEST
				&:nth-of-type(2n) {margin-right: 0%;}
				&:nth-of-type(2n+1) {clear: left;} // TEST
				
				} // MEDIA-QUERY	
			@media only screen and (max-width: $bp_s) { 
				@include fullwidth; 
				margin-right: 0%; 
				
				margin-bottom: $spacing075; // TEST 
				
				} // MEDIA-QUERY	
			
			} // col
			
			
			
	
	.item {
		background-color: #EAE0DE; // EXTRACTED // COOULD BE APPLIED JUST TO.. NO, MUST BE HERE IF I USE JS..
	
	
		// ANCHOR IT AL, WITH HOVER MOUSENETER EFFECT ADDCLASS (IMAGE OPCAITY & TITLKE UNDERLINE (WITH NICER UNDERLINE, AND MULTILINE!!! DE IN&OUT ETC """
		
		//JQUERY
		&.is-active {
			.image .imageCover {
				// display: block;
				background-color: rgba(#fff, 0.3);
				}
			.title {text-decoration: underline;}		
			} // is-active
	
	
	
		.image {
			// BG IMG
			@include bg_img;
			@include ar_4_3; // TEST, creo que ok
			// img {@include img;}
			
			position: relative;
			.imageCover {
				// display: none; // SHOW WITH JQUERY
				background-color: rgba(#fff, 0.0);
				// background-color: rgba(#fff, 0.5);
				position: absolute; top: 0px;
				width: 100%;
				height: 100%;
				} // imageCover
			
			
			
			} // image				
		
		.info {		
			// @include paddingv($spacing2); // TEST // OJO SAME HEIGHT TRICKL DE JS !!!!!!!!!!!!!!!!
			padding-top: $spacing; 
			padding-bottom: $spacing2;
			@include paddingh($spacing); // TEST
			
			// border: 1px solid red; // dev
			
			
			.title {
				// text-transform: uppercase; // PRECAUTION
				font-size: $fs_l;
				// font-size: $fs_xl; // TEST
				// margin-bottom: $spacing; // TEST
				
				
				// ANCHOR - for reset / anchor inside p should behave as normal anchors.
				a { 
					
					&:hover {
						text-decoration: underline;
						}
					
					
					} // a
				
				
				} // title

			} // info
			
			
			
			
			
		@media only screen and (max-width: $bp_m) {
			
				
			.image {
				// BG IMG
				// @include ar_16_9; // TEST, PARA MENOS ALTURA, YA QUE LAS IMAGENES SON BULLSHIT ANYWAYS.
				// @include ar_cinemascope; // TEST
				// border: 5px solid black; // DEV
				} // image	
				
			/*
			.info {		
				// @include paddingv($spacing3); // TEST
				@include paddingv($spacing2); // TEST
				// @include paddingh($spacing3); // TEST
				@include paddingh($spacing2); // TEST
				} // info
			*/	
							
			} // MEDIA-QUERY	
		
		
			
		@media only screen and (max-width: $bp_s) {
			/*
			.info {		
				@include paddingv($spacing); // TEST
				@include paddingh($spacing); // TEST
				} // info
			*/
							
			} // MEDIA-QUERY
			
			
			
					
		} // item
		
		
	}	// gridNewsPage
	
	
	