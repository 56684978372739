
// ABOUT - CERTIFICATES, COPIED KINDA GENERIC INTRO TEXT ...


section.certificatesText { // WAS sectorIntroText
	// border: 10px solid green; 
	
	// @include paddingv($spacing2); // TEST
	// @include paddingv($spacing3); // TEST
	// padding-top: $spacing4;
	// padding-top: $spacing2;
	padding-top: 36px;
	
	text-align: center;
	
	// ACF
	.title {
		text-transform: uppercase; // PRECAUTION
		// font-size: $fs_l; // TEST
		font-size: $fs_xl; // TEST
		@include f_main_bold;
		margin-bottom: $spacing; // TEST - same separation que entre p's
		// border: 1px solid red; 
		} // title
	h2 {
		text-transform: uppercase; // PRECAUTION
		// font-size: $fs_l; // TEST
		font-size: $fs_xl; // TEST
		@include f_main_bold;
		margin-bottom: $spacing;
		}
	
	
	
	.text {
		// font-size: $fs_m; // TEST
		font-size: $fs_l; // TEST
		padding-bottom: 36px; // $spacing2; // !!!!!!!!!!
		// 
		/*
		p {
			margin-bottom: $spacing075; // TEST - thios was fo rcertifoicate spage really ... 
			}
		*/	
			
		h1, h2, h3, h4, h5, h6 {
			@include f_main_bold;
			}		
			
		a {
			font-size: $fs_m; // TEST - FOR LOOSE CASES I CERTIFICATES, BUT NOT IN COOMITEMETS PAGE.. 
			
			text-decoration: underline;
			&:hover {text-decoration: none;}
			} // !!!!!!!!!!!
		
					
		} // TEXT
	
	
	} // certificatesText 
	
	
	
	
	
// hay conflicots con generic tetx thingeee !! cerate el moduel par ael title y pista !!!!
	

// PAGE: ABOUT
// SECTION: Vision & Values
	
.w_colorBoxes	{ // JS - ALL SAME HEIGHT :-)
	// border: 1px solid red; // DEV
	@extend .clearfix;
	
	.col {
		float: left;
		width: 49%;
		margin-right: 2%;
		&:nth-of-type(2n) {margin-right: 0%;}
		margin-bottom: 2%;
		&:last-of-type {margin-bottom: 0%;} // PRECAUTION
		
		&:nth-of-type(odd) {text-align: right;}
		
		.item {
			// bg color defined through database
			padding: $spacing; // TEST
			
			color: $c_white;
			// font-size: $fs_l; // TEST
			font-size: $fs_xl; // TEST
			
			.title {
				@include f_main_bold;
				// font-size: $fs_l; // TEST
				
				// HACK
				margin-bottom: 0px; // OVERRIDE
				}
			
			.text {
				
				}
			
						
			} // item
			
		
		} // col
	
		@media only screen and (max-width: $bp_m) {
	
			.col {
				@include fullwidth;
				margin-right: 0%;
				} // col
				
			} // MEDIA-QUERY
						
					
						
	} // w_colorBoxes













section.aboutListBoxes { // COPIED FOMR "sectorListBoxes"
	// border: 10px solid green; // DEV TEMP

	
		.gridListBoxes  {
			@extend .clearfix; // TEST // WORKS
			
			.col { // same as in genericIntroText
				float: left;
				width: 50%;
				&:nth-of-type(2n+1) {clear: left;}
				
				@media only screen and (max-width: $bp_m) { 
					@include fullwidth; 
					} // MEDIA-QUERY		
						
				} // col
			
			.item {

				.info {	
					@include paddingv($spacing3);
					@include paddingh($spacing3);
				
					.title {
						text-transform: uppercase; // PRECAUTION
						font-size: $fs_xl;
						@include f_main_bold;
						// margin-bottom: $spacing;
						} // line1
						
						
					.text {
						font-size: $fs_l;
						margin-bottom: $spacing; // TEST - TEST
						
						a {
							text-decoration: underline;
							&:hover {text-decoration: none;}
							}
						
						} // line1
						
												
					.list {
						font-size: $fs_l;
						margin-bottom: $spacing; // tTEST
						
						ul {}
						
						li {
							list-style: disc; // TEST
							// margin-left: $spacing075; // TEST
							margin-left: $spacing; // TEST
							
							ul {}
							
							li {
								list-style: disc; // TEST
								margin-left: $spacing075; // TEST
								}
							
							} // li

						} // list	
		
		
					.details { // FOR ABOUT PAGE 
						
						&.case-all-bold { // ABOUT PAGE, 4 BOXES...
							@include f_main_bold;
							}

						// margin-top: $spacing; // TEST
						margin-top: 36px; // TEST
						// @include f_main_bold;
						// font-size: $fs_m; // TEST
						font-size: $fs_s; // TEST
						
						
						.detailsTitle { // ?!?!?!?!?!
							@include f_main_bold;
							}
						
						a {
							text-decoration: underline;
							&:hover {text-decoration: none;}
							}
						
						} // details
						

						.image {
							img {@include img}
							}
						
					} // info
					
				} // item
				
			}	// gridListBoxes
			

	} // aboutListBoxes 