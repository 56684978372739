.togglebutton {
	display: none; // TEMP
	}





nav#menuresponsive {
	float: right; // TEMP
	// margin-top: $spacing2; // TEST
	// margin-top: 58px; // TEST
	margin-top: 51px; // TEST
	
  // margin-right: $spacing; // SAME AS SPACE BETWEEN ELEMENTS
	// margin-right: $spacing2; // but need more as i0ve talken away the h padding on frame header
	margin-right: $spacing3;
	
  ul.menu {
    overflow: hidden;
		
		// !!! HIDE HOME A LO CUTRE
		> li:first-of-type {display: none;}
		
    li {
      float: left; // only first level...  
      margin-right: $spacing05; // TEST 
      &:last-of-type {margin-right:0px;}
      
      a { // BUTTON BEHAVIOUR !!!
        display: block;
        padding: $spacing025 $spacing05;
				
				font-size: $fs_l;
				
				/*
				@include transitionButton; // TEST
        &:hover {
          background-color: $c_black;
          color: $c_white;
          }
        */
				
				// border: 1px solid black; // DEV
				
        } // a
    
			
			&:hover {
				ul {display: block;}
				}
					
			
			ul {
				display: none;
				background-color: $c_white;
				// padding-top: $spacing2;
				padding-top: 42px; // TEST
				padding-bottom: 36px; 
				
				position: absolute;
				
				li {
					float: none;
					margin-right: 0%; // RESET 
					
					/* 3rd LEVEL */
					ul {
						// border: 3px solid red; // DEV
						padding-top: 0px; // RESET
						position: relative; // RESET
						padding-bottom: 0px; // RESET
						// margin-left: $spacing05; // TEST
						margin-left: $spacing075; // TEST
						} // ul

					} // li
				
				
					// https://stackoverflow.com/questions/25952496/prevent-nested-nav-items-from-breaking-on-to-the-next-line
					li a {
						white-space: nowrap;
						
						// border: 1px solid red; 
						// width: 100%;	
						}
				
				
								
				} // ul
			

		
		
      } // li
    
    
    
  } // ul.menu
  
  
  
  
  
} // nav#menuresponsive