

.slickslider {
/*
background-color: #f0f;
padding: 100px;
border: 3px solid red;
*/
height: 100%; /* VIP FOR FULLHEIGHT SCENARIO */

// position: relative;

// border: 3px solid black;	


} // ONLY MY DEMO PURPOSES


/* 2 CLASSES INJECTED BY THE SCRIPT */
.slick-list {
	height: 100%;
	position: relative;
	}
	.slick-track {
	height: 100%;
	position: relative;
	}



/*

.slickslider_slide {
	background-color: #f0f;
	border: 3px solid red;
	height: 100%;
	// position: relative;
	// height: 100vh;
	}

*/

.content222 {
	/* margin:auto; */
	/* padding:0px 20px; */ /* ESTO ES LO QUE PERMITE MOSTRAR LOS NAV THINGEES !  */
	/* padding:0px 0px; */
	/* padding:10px 0px; */
	/* width:600px; */

	// position:relative;

	/* background-color:#ccc; */
	/* padding:10px; */
	}




.slick-slide img {
	/* border:5px solid #FFF; */ /* borrar esta linea */

	display:block;
	width:100%;
	}






/* Slider */
.slick-slider { position: relative;
               display: block;


// height same as fullpage image
height: 62vh;



               box-sizing: border-box; -moz-box-sizing: border-box; -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; -ms-touch-action: none; touch-action: none; -webkit-tap-highlight-color: transparent; }






.slick-list {

  z-index:100;

  position: relative; overflow: hidden; display: block; margin: 0; padding: 0; }

.slick-list:focus { outline: none; }
.slick-loading .slick-list { background: white url(./ajax-loader.gif) center center no-repeat; }
.slick-list.dragging { cursor: pointer; cursor: hand; }

.slick-slider .slick-list, .slick-track, .slick-slide, .slick-slide img { -webkit-transform: translate3d(0, 0, 0); -moz-transform: translate3d(0, 0, 0); -ms-transform: translate3d(0, 0, 0); -o-transform: translate3d(0, 0, 0); transform: translate3d(0, 0, 0); }






.slick-track { position: relative; left: 0; top: 0; display: block; zoom: 1; }
.slick-track:before, .slick-track:after { content: ""; display: table; }
.slick-track:after { clear: both; }
.slick-loading .slick-track { visibility: hidden; }



.slick-slide {
	float: left;
        // height: 100%; // antes esto no molestaba, pero ahora tengo que comentarlo pk osino el trexto sse pone denajo de la imagen
        min-height: 1px;
        display: none;
	z-index:1;
        position: relative;
	}





.slick-slide img { display: block; }
.slick-slide.slick-loading img { display: none; }
.slick-slide.dragging img { pointer-events: none; }
.slick-initialized .slick-slide { display: block; }
.slick-loading .slick-slide { visibility: hidden; }
.slick-vertical .slick-slide { display: block; height: auto; border: 1px solid transparent; }

/* Icons */
/*
@font-face { font-family: "slick"; src: url("./fonts/slick.eot"); src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg"); font-weight: normal; font-style: normal; }
*/

/* Arrows */

.slick-prev, .slick-next {
	z-index:100; /* CARL */
	/* color:#f00; */ /* CARL */
	position: absolute;
	display: block;
	/*
	height: 20px;
	width: 20px;
	*/
	/*
  height: 40px;
	width: 40px;
	*/
	/* width: 60px;  */
	line-height: 0; font-size: 0;
	cursor: pointer;
	background: transparent;
	// background-color:#999;
	// color: transparent;
	/* color: #fff; */ /* TEST DE CARL - para el texto de los arrows - NO HA FUNCIONADO */
	top: 50%;
	/*
	height: 20px;
	width: 20px;
	margin-top: -10px;
	*/
	height: 40px; // !!!!!
	width: 40px; // !!!!
	margin-top: -20px;

	padding: 0;
	border: none;
	outline: none;
	}


// RWD - POSICIONAR LAS FLECHAS ARRIBA A PARTIR DE SCENARIO TABLET

/*
	@media only screen and (max-width: $bp_m) {

		.slick-prev, .slick-next {
			// top: 50%;
			top: 117px; //  but maybe even better negative...
			// background-color:#999;
			}

		} // MEDIA-QUERY

*/



.slick-prev:focus, .slick-next:focus { outline: none; }


.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
	opacity: 0.25; /* efecto interesante */

	display:none; /* TEST DE CARL */

        z-index:0;
	}



.slick-prev:before,
.slick-next:before {
	// font-family: "slick";
	// font-size: 20px;
	// font-size: 48px;
	font-size: 64px;
	// font-family: "Impact";

	/* font-size: 60px; */
	// line-height: 1;
	// line-height: 0.6em;
	// color: #fff; /* vip, color del no-arrow - err, del arrow*/

	// color: #403e42;
	color: #ffffff; // TEST

	font-weight:bold;
	/* background-color:#f00; */
	/* padding:15px 20px; */

	display:block;
	/* height:100%; */
	padding:10px 0px;

	// background-color: $c_main_dark;
	// background-color: #FF5722; // orange accent color

// background-color: #403e42;
// background-color: #00F;

	opacity: 0.85; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;

	// @extend .s_buttonBase;
	// @extend .s_buttonBaseAlt;

	}

/* CARL - EFECTO HOVER */
/*
.slick-prev:hover:before,
.slick-next:hover:before {
	// background-color: $c_main;
	background-color: lighten(#FF5722, 5%);
	}
*/









.slick-prev {
	/* left: -25px;  */
	// left: 0px;
	left: 48px;
	// left: -48px;
	// left: -96px;
	}
.slick-prev:before {
     content: "<";
    /* font-family: 'Gentium Basic', sans-serif; */
		//content:url('../img/icons/ui/svg/previous.svg'); /* with class ModalCarrot ??*/
		//content:url('../img/icons/ui/svg/batch_2_Arrow.svg'); /* with class ModalCarrot ??*/
		// background:url('blackCarrot.png');


		// CSS ROTATE 180º ... BUT MIGHT NEED A WRAPPER SPOECIFIC FOR THE ICON...
		// -ms-transform: rotate(180deg); /* IE 9 */
		// -webkit-transform: rotate(180deg); /* Safari 3-8 */
		// transform: rotate(180deg);
    }
		
		
		
		
		

.slick-next {
	/* right: -25px; */
	// right: 0px;
        // right: -2px;
	right: 48px;
	// right: -48px;
	// right: -96px;
	}

.slick-next:before {
	content: ">";
	// content:url('../img/icons/ui/svg/next.svg'); /* with class ModalCarrot ??*/
	// content:url('../img/icons/ui/svg/batch_2_Arrow.svg'); /* with class ModalCarrot ??*/
	}




	// MEDIA QUERY IT !!!!

/*
	@media only screen and (max-width: $bp_m) {

		.slick-prev {
			left: 0px;
			}

		.slick-next {
			right: 0px;
			}

		} // MEDIA-QUERY
*/















/* Dots */
/* .slick-slider { margin-bottom: 30px; } */


.slick-dots {
	position: absolute;
	// bottom: -45px;
	// bottom: 24px;
	bottom: -40px;
	z-index:101;

	list-style: none; display: block; text-align: center; padding: 0px; width: 100%;


	// background-color: #f00;
	}


.slick-dots li { position: relative; display: inline-block;
/*
height: 20px;
width: 20px;
*/
height: 10px;
width: 10px;

// margin: 0px 5px;
margin: 0px 10px;
padding: 0px;
cursor: pointer;


border-radius: 50%;
// border: 3px solid #403e42;
background-color: $c_white;
}


.slick-dots li button {
border: 0; background: transparent; display: block; height: 20px; width: 20px; outline: none; line-height: 0; font-size: 0; color: transparent; padding: 5px; cursor: pointer; outline: none;

// border: 3px solid #00f;

}

/* CARL - pk no iba el current .. */
.slick-dots li.slick-active {
// background-color: $c_text;
background-color: red;
 }




.slick-dots li button:focus { outline: none; }

.slick-dots li button:before {
position: absolute; top: 0; left: 0;
// content: "\2022"; /* QUITADO ORQUE CAUSABA UN DOT EN EL MEDIO */

width: 20px; height: 20px; font-family: "slick"; font-size: 6px; line-height: 20px; text-align: center; color: black; opacity: 0.25; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }

.slick-dots li.slick-active button:before { opacity: 0.75; }
