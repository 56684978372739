/*
 * Author: Carl Johansson @carlwebdev
 * Project: cowo-Reset
 * File: Header
 */

/*
#w_headroom {
	
	}
*/

// use sticky instead, copy de barlabeata...


	
header {
	
	
	
	
	
	/* START - STICKY */
	// As you can see in that demo, it's a decent candidate for progressive enhancement, as if you don't see the sticky header feature, it's no big deal.
	// http://caniuse.com/#feat=css-sticky

	/*
	position: -webkit-sticky;
	position: sticky;
	top: 0px; // VIP
	*/
	
	// z-index: 2; // over map / not needed for sticky behaviour (THIS IS COPY PASTED)
	/*
	@media only screen and (max-width: $bp_l) {
		position: relative; // RESET
		} // MEDIA-QUERY	
	*/
	/* END - STICKY */	
  
	
	
	&.case-is-fixed {} // or viceversa, for pages that don't require init image etc ... 
	
	
	/*
	position: fixed; 
	top: 0px;
	width: 100%;
	*/
	
	z-index: 10000; 
	
	// DEV
	// border-bottom: 1px solid black;
	
	.inner {
		// padding: $spacing025; 
		// padding: $spacing05; // TEST DOUBLE
		@include paddingv($spacing075);
		// overflow:hidden;
		@extend .clearfix;
		position:relative;
		}
	
	} // header
	











		.navicon {	
			cursor:pointer;
			float:left;
			width:36px;
			
			margin-right: $spacing05; // TEMP
			
			// img {@include img;}
		
					
			// display: inline-block !important;
			
			// background-color: #999; // TEST

			display: none;
			@media only screen and (max-width: $bp_m) { 
				display: block !important;	
				// display: inline-block !important;	
				} // MEDIA-QUERY	



	
					.icon { // esto aplica el icon font, solo aki, po rahora, pero lo he usado en otros sitios, y siempre uso el mismo style, osea que metelo como un mixin !!!!! @include icon, ah no!!!! no cal fer res, o no, si que hace falta !!!!!!!!!!
						// background-color: #ff0; // TEST
						display: block;
						height: 1em; // YES !!!!!!!!!!!!
						// font-size: 48px;
						font-size: 32px;
						// color: $c_green;
						color: #fff;
						// @include transition(color, 0.25s);
						// &:hover {color: $c_green_light;	}
						} // icon
		

	
		} // navicon	








.logoMain { // RENAME TO logoMain !!!! in css & html
	float:left;
	// margin-left: $spacing025; // TEST, ADD THIS JUST TO LOGO CSS	
	// margin-left: $spacing05;	
	margin-left: $spacing075;
	// width: 300px; // TEMP
	// width: 160px; // TEST 
	// width: 120px; // TEST
	width: 100px; // TEST
	width: 90px; // TEST
	img {@include img;}
	} // logoMain



		
@media only screen and (max-width: $bp_m) { 

	.logoMain {
		/*
		float:none;
		text-align:center;
		*/
		// display: none;
		}

				
} // MEDIA-QUERY		



@media only screen and (max-width: $bp_s) { 


					
} // MEDIA-QUERY	
