/*
 * Author: Carl Johansson @carlwebdev
 * Project: cowo-Boilerplate
 * File: Page Home
 */
 
 
	
/* PAGE: SERVICE (Single)
 * MODULE: LIST BOXES  - COPIED ALMOST AL LFORM SECTRO SLIST BOXES
 */		
	
// MAYBE ADD MAIN WRAPPER, FOR NTH OF TYPE TO WORK BETTER..

	
	
	
	
section.serviceListBoxes { // COPIED FOMR "sectorListBoxes"
	// border: 10px solid green; // DEV TEMP


	&:nth-of-type(odd) { // OK
		background-color: #E9E1DD; // BEIGE 		
		// border: 3px solid red; // DEV TEMP - 
		}
		
		
	&:nth-of-type(1) { // OK
	
		.gridListBoxes .item .info .details {
			
			display: block !important;
			
			// border: 1px solid red;
			}
	
		} // first
		
				
	/*	
	&.case-bg-beige {
		background-color: #E9E1DD; // BEIGE 
		}
	*/
	
	
		.gridListBoxes  {
			@extend .clearfix; // TEST // WORKS
			
			.col { // same as in genericIntroText
				float: left;
				// width: 50%;
				// width: 49%
				width: 48%;
				// margin-right: 2%; // TEST
				margin-right: 4%; // TEST
				&:nth-of-type(2n) {margin-right: 0%;} // TOO VERBOSE, BUT FOR PRECAUTION
				
				// border: 1px solid red; // dev
				
				// RWD	
				@media only screen and (max-width: $bp_m) { 
					@include fullwidth; 
					} // MEDIA-QUERY	
				
				// case about page usage, need margin botttom
				// and also need the contact info modfuel		
						
				} // col
			
			.item {

				.info {
					// padding: $spacing2; // TEST DEV
					// padding: $spacing3; // TEST DEV		
					@include paddingv($spacing3);
				
					.title {
						text-transform: uppercase; // PRECAUTION
						font-size: $fs_xl;
						@include f_main_bold;
						margin-bottom: $spacing;
						} // line1
						
					.text {
						font-size: $fs_l;
						margin-bottom: $spacing; // TEST - TEST
						
						a {
							text-decoration: underline;
							&:hover {text-decoration: none;}
							}
						
						} // line1
						
												
					.list {
						font-size: $fs_l;
						margin-bottom: $spacing; // tTEST
						
						ul {}
						
						li {
							list-style: disc; // TEST
							// margin-left: $spacing075; // TEST
							margin-left: $spacing; // TEST
							
							ul {}
							
							li {
								list-style: disc; // TEST
								margin-left: $spacing075; // TEST
								}
							
							} // li
							
							
						
						} // list	
		
		
					.details { // FOR ABOUT PAGE 
						
						&.case-all-bold { // ABOUT PAGE, 4 BOXES...
							@include f_main_bold;
							}
						
						
						
						// display: none; // SHOW ONLY IN 1ST BOX !!!
					
						// margin-top: $spacing; // TEST
						margin-top: 36px; // TEST
						// @include f_main_bold;
						// font-size: $fs_m; // TEST
						font-size: $fs_s; // TEST
						
						// 2ND SECTION WITH HEIGHT SEPARATION.. - NO, NOT NEEDED
						
						.detailsTitle {
							@include f_main_bold;
							}
						
						a {
							text-decoration: underline;
							&:hover {text-decoration: none;}
							}
						
						} // details
						
						
						
						.image {
							img {@include img}
							}
						
						
						
						
								
					} // info
					
					
				} // item
				
			}	// gridListBoxes
			


	} // serviceListBoxes 
	
	

	
	
		