/* 
 * Project: Permacultura.cat
 * File: Cookies
 *
 */

.modal_leydecookies { // m_ or ????  // modal_lorem !!!!!!!!!!!!!!!!!
		background-color: $c_primary_light;
    @include paddingv($spacing025); 
		
		position: fixed;  
		bottom: -200px; // es la altura que escondo mediante jquery - ahi debveria ser un toggle class para que funcione autonomamente
		width: 100%;
    } // modal_leydecookies


    .leydecookies_content { // inner ...must be nested in trhat case...
        position: relative;
        @extend .clearfix;	
				text-align: center;
				
				.text {
					display: inline-block;
					margin-right: $spacing;
					color: $c_text_2; // secondary text
					// color: $c_white; // secondary text
					font-size: 12px;
					}
        
        .options {
						display: inline-block;
						vertical-align: middle;					
            @extend .clearfix;
						
						ul {}

            li {
							float: left;
							margin-right: 10px;
							&:last-of-type {margin-right: 0;}

							a {
								cursor: pointer; /* para el ajax onclick - pasar esto a este reset, y luego al cowo-Reset  */
								display: block; 
								// padding: 5px 10px;
								padding: 4px 8px;
								// width: 100px;
								min-width: 100px;
								min-width: 60px;
								text-align: center;
								
								font-size: 12px;
								color: #fff;
								
								@include transition(background-color, 0.5s); // sass it !!!!!!!!
								background-color: $c_primary;
								
								&:hover {
									// background-color: darken($c_green, 5%);
									background-color: $c_primary_dark;
								}

								} // a


            } // item


        	} // options
								
        } // leydecookies_content






@media only screen and (max-width: 1024px) {
	

    
  
      .leydecookies .options {
  
				
						// display: none;
						
						padding-top:12px; // ???
						

        } // content
    
    
    

} // MEDIA-QUERY





@media only screen and (max-width: 800px) {
	
    .leydecookies .options {
  
				
						margin-bottom:12px; // ???
						

        } // content
    
  
    
    
    

} // MEDIA-QUERY





@media only screen and (max-width: 600px) {

    
  
    
    /*
        .leydecookies .options {
            width:100%;
            overflow: hidden; 
            text-align:center;

            .item {
                // float: left;
                float: none;
                display:inline-block;
                
                margin-right: 10px;

                &:last-of-type {margin-right: 0;}
								
            	} // item


        } // options
    
    */
    

} // MEDIA-QUERY


