/*
 * Author: Carl Johansson @carlwebdev
 * Project: cowo-Boilerplate
 * File: Footer
 */
 
footer {
	overflow:hidden;
	
	.inner {
		overflow:hidden;
		// padding: $spacing05; // !!!!!!!
		// @include paddingv($spacing3); // TEST
		@include paddingv($spacing4); // TEST
		// text-align: center;    
    color: $c_white;	
		
		@media only screen and (max-width: $bp_l) {
			@include paddingv($spacing2); // TEST
			} // MEDIA-QUERY	
					
		} // inner
			
	} // footer

	
// CALLTO !!!!!	
	

// ADD FOOTER GRID FOR FATS LINKS ETC 

.gridFooter {
	float: right;
	width: 62%;
	// width: 50%;
	// width: 75%;
	@include transition(width $t_fast linear);
	
	color: $c_white;	

	overflow: hidden;
	// vs align system !!!
		
	.col {
		float: left;
		// width: 25%; // TEST
		// width: 50%; // TEST
		@include transition(width $t_fast linear);
		
		&:nth-of-type(1) {
			width: calc(2*(100%/3))
			}
			
		&:nth-of-type(2) {
			width: calc(1*(100%/3))
			}
			
		// border: 1px solid red; // DEV
		} // col
	
	.item {
		
		font-size: $fs_l;
		
		
		.title,
		h3 {
			// font-weight: bold; // TEMP
			@include f_main_bold;
			// font-size: $fs_l;
			} 
		
		ul {
			// margin-bottom: $spacing05; // TEST
			margin-bottom: $spacing075; // TEST
			&:last-of-type {margin-bottom: 0px;}
			
			// font-size: $fs_m;
			line-height: 1.4em; // TEST
			}
		
		li {}
		
		
		a {
			color: inherit; // BASE REST EFILE !!!
			&:hover {text-decoration:underline;} // maybe not enclose the work "Mail" i the fort... 
			} // a
		
		
		.socialIcon { // adapt it with same color !!!
			margin-top: $spacing; // TEST // OK
			width: 32px; // OK
			img {@include img;}
			} // socialIcon
		
		
		} // item
		
		
  @media only screen and (max-width: $bp_xxl) {
		width: 62%;
		} // MEDIA-QUERY	
		
  @media only screen and (max-width: $bp_xl) {
		width: 75%;
		} // MEDIA-QUERY	
			
  @media only screen and (max-width: $bp_l) {
		@include fullwidth;
		
		
		/*
		.col {
			
			&:nth-of-type(1) {
				width: calc(3*(100%/4))
				}
				
			&:nth-of-type(2) {
				width: calc(1*(100%/4))
				}
				
			} // col
		*/
		
		} // MEDIA-QUERY	
		
  @media only screen and (max-width: $bp_m) { // was s

		.col {
			
			&:nth-of-type(1),
			&:nth-of-type(2) {
				@include fullwidth;
				}
									
			&:nth-of-type(1) {
				// margin-bottom: $spacing; // TEST
				margin-bottom: $spacing2; // TEST
				}		
			} // col
			
		} // MEDIA-QUERY	
				
		
	
	} // gridFooter