/* CSS Document - FRONT-END */

/* =================================================================== */
/* LIGHTBOX ME                                       				   */
/* =================================================================== */


.lightboxme_trigger {
	cursor:pointer;
	background-color:$c_primary; 
	float:left;
	padding:$spacing;
	color:#fff;
	
	&:hover {
		background-color:$c_primary_dark;    // MUST EXTEND BUTTON STYLE CLASS OR MIXIN FOR THIS S!!!!!!!!!!!!!!!!!!!!! TEST WITH MIXIN, i THINK IT WAS OK , YEAH....
		}
		
			
	} // lightboxme_trigger


	
.lightboxme_content {
	clear:right; 
	display:none; 
	background-color:$c_primary_light; 
	color:#fff;
	/* width:700px; */
	width:62%; 
	
	padding:$spacing;
	}

	.lightboxme_content .close {
		cursor:pointer;
		text-align:right;
		margin-bottom: $spacing;
		
		&:hover {color:$c_primary;}
					
		}
		