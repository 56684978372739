/*
 * Author: Carl Johansson @carlwebdev
 * Project: cowo-Boilerplate
 * File: Page Home
 */
 
 
/* PAGE: SERVICES
 * MODULE: INTRO TEXT  -SECTOR INTRO TEXT 
 */		
 
section.w_gridServicesPage { // USED IN: Homepage, ...
	// border: 10px solid green; 
	} // w_gridServicesPage
	
.gridServicesPage {
	// overflow: hidden;
	@extend .clearfix; // TEST // WORKS
	
	
		&:nth-of-type(odd) .item {
			// border: 3px solid green;
			.col {float: right;} // TEST - because in the html i hav eplaced the image before the text
			} // odd
		
		&:nth-of-type(even) .item {
		  // border: 3px solid orange;	
			// NOTHING NEEDS TO BE DONE HERE
			} // even
			
			
				
	a { // THE NEW WRAPPER
		
		/*
		&:nth-of-type(odd) {
			border: 3px solid blue;
			.item .col {float: right;} // TEST - because in the html i hav eplaced the image before the text
			} // odd
		
		&:nth-of-type(even) {
			border: 3px solid yellow;	
			// NOTHING NEEDS TO BE DONE HERE
			} // even
		*/	
					
		} // a
		
		
		
		
		
	
	.item {
		@extend .clearfix; // HACK
		
		// border: 3px solid red; 
		
		
		// JQUERY
		&.is-active {
			// DO SOMETHING
			.image .imageCover {
				// display: block;
				background-color: rgba(#fff, 0.3);
				}
				
			.info .arrow .anchor .layer {display: none;}
			
			} // is-active
			
			
			
		
		// HACK DEL BG COLOR, NO CAL JS, SINO SIMLEMENET APLLY COLORO TO WHOLE VITEMBOX !!!!
		// numbers indicate increasing darkness
		/*
		&.bg_1 { // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! move colors to up one level... 
			background-color: #FFFFFF;
			color: $c_black;
			}
		&.bg_3 {
			background-color: #9A9B9C;
			}
		&.bg_4 {
			background-color: #616365;
			color: $c_white; // ????
			}
		&.bg_2 {
			background-color: #E9E1DD;
			}		
		*/		
				
				
				
		// IN THIS CASE: cols must be in here, for aspectratio to work... and anyways, the other col top systemis useless  thsi scenario
		.col {
			float: left;
			width: 50%;
			// border: 3px solid blue; 
			
			// RWD
			/*
			@media only screen and (max-width: $bp_s) { 
				width: 50%;
				} // MEDIA-QUERY			
			@media only screen and (max-width: $bp_xs) { 
				@include fullwidth; 
				} // MEDIA-QUERY	
			*/
			} // col
		
		/*
		&:nth-of-type(odd) {
			// border: 3px solid green;
			.col {float: right;} // TEST - because in the html i hav eplaced the image before the text
			} // odd
		
		&:nth-of-type(even) {
			// border: 3px solid orange;	
			// NOTHING NEEDS TO BE DONE HERE
			} // even
		*/
	
		// OJO !!!! COL GROUPMG MUST BE ADJUSTED WITH S TRICK !!! FOR RWD SCENARIOS - - - BUT ALSO MAKE ALL FLUSH INTO COLUMNS.... WITH ALWAYS SAME OIRDER, AKA UNDO TEH EBEN / PODD SCANRIO WHEN 1 COLUMN SYSTEM..
		
		

	
		.image {
			// BG IMG
			@include bg_img;
			@include ar_4_3; // TEST, creo que ok
			// img {@include img;}
			
			position: relative;
			.imageCover {
				// display: none; // SHOW WITH JQUERY
				background-color: rgba(#fff, 0.0);
				// background-color: rgba(#fff, 0.5);
				position: absolute; top: 0px;
				width: 100%;
				height: 100%;
				} // imageCover
							
			} // image				
		
		.info {		
			// color: $c_white;
			
			// @include paddingv($spacing2); // TEST
			@include paddingv($spacing3); // TEST
			// @include paddingh($spacing2); // TEST
			@include paddingh($spacing3); // TEST
			
			.line1 {
				text-transform: uppercase; // PRECAUTION
				// font-size: $fs_l;
				// font-size: $fs_xl; // TEST
				font-size: $fs_xxl; // TEST
				@include f_main_bold;
				margin-bottom: $spacing; // TEST
				} // line1
				
			.line2 {
				// font-size: $fs_m;
				// font-size: $fs_l; // TEST
				font-size: $fs_xl; // TEST
				
				} // line2	
				
				
			.arrow { 
				
				// font-size: $fs_xl;
				font-size: 80px; // TEST
				line-height: 0.2em; // TEST
				/*
				&.is-active {
					// DO SOMETHING
					border: 3px solid red; // DEV
					} // is-active
				*/
				
				// a,
				.anchor {
					display: block;
					margin-top: $spacing; // TEST
					// margin-bottom: $spacing2; // TEST
					
					float: right;
					margin-right: $spacing2; // TEST
					// width: 100px;
					// border-bottom: 2px solid white;
					// color: $c_white;
					// background-color: #ccc; // dev
					
					position: relative;
					
					.actual { // relative to the a, or anchor..
	
						} // 
									
					.layer { // relative to the a, or anchor..
						// border: 1px solid red; 
						width: 23px;
						height: 30px;
						position: absolute; 
						right: 0px;
						top: 2px;
						} // 
					
					} // a
			
			
				} // arrow	
				
				
				
				
			
			
			} // info
			
			
			
			
			
		@media only screen and (max-width: $bp_m) {
			
			// item
			// border: 5px solid #f06; // DEV
			.col {
				@include fullwidth;
				} // col
				
			.image {
				// BG IMG
				// @include ar_16_9; // TEST, PARA MENOS ALTURA, YA QUE LAS IMAGENES SON BULLSHIT ANYWAYS.
				@include ar_cinemascope; // TEST
				// border: 5px solid black; // DEV
				} // image	
				
			.info {		
				// @include paddingv($spacing3); // TEST
				@include paddingv($spacing2); // TEST
				// @include paddingh($spacing3); // TEST
				@include paddingh($spacing2); // TEST
				} // info
				
							
			} // MEDIA-QUERY	
		
		
			
		@media only screen and (max-width: $bp_s) {
			
			.info {		
				@include paddingv($spacing); // TEST
				@include paddingh($spacing); // TEST
				} // info
							
			} // MEDIA-QUERY
			
			
			
					
		} // item
		
		
	}	// gridServicesPage
	
	
	