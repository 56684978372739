




section.w_contactPage { // USED IN: Homepage, ...
	// border: 10px solid green; 
	
	
	padding-top: $spacing2; // TEST
	padding-bottom: $spacing4; // TEST
	
	
	
	
	.titleMain {
		text-transform: uppercase; // PRECAUTION
		font-size: $fs_xxl; // TEST
		@include f_main_bold;
		margin-bottom: $spacing2; // TEST
		} // titleMain
		
		
	.item {
		margin-bottom: $spacing; // TEST - evven the last one, for bottom padding on page
		margin-bottom: $spacing2; // test, maybe too much
		
		// margin-bottom: 36px;
		// margin-bottom: $spacing2;
		
		.title {
			font-size: $fs_xl; // TEST
			@include f_main_bold;
			} // titleMain
			
		.titleSecondary {
			// font-size: $fs_xl; // TEST
			font-size: $fs_l; // TEST
			@include f_main_bold;
			} // titleMain		
			
			
		
		a { // SASS THIS THING...
			text-decoration: underline;
			&:hover {text-decoration: none;}
			}	
			
			
			
			
		.blockAddress {
			margin-bottom: $spacing; 
			
			font-size: $fs_l; // TEST
			line-height: 1.4em; // TEST
			
			
			&.case-head-office {
				// MAYBE NO MARGGN BOTTOM
				
				.row {
					@extend .clearfix;
					}
				
				.col {
					float: left;
					width: 23.5%;
					&:first-of-type {width: 49%;}
					margin-right: 2%;
					&:last-of-type {margin-right: 0%;}
					} // col
			
				@media only screen and (max-width: $bp_m) {
	
					.row {
						margin-bottom: $spacing05; // TEST
						&:last-of-type {margin-bottom: 0px;}
						} // row
						
					.col {
						@include fullwidth;
						margin-right: 0%;
						
						&:first-of-type {
							@include fullwidth;
							margin-right: 0%;
							}
							
						} // col
						
						
					} // MEDIA-QUERY
					
				
				
				
				} // case-head-office 
			
			
			
			
				@media only screen and (max-width: $bp_s) {
					span {display: block;} // RWD	
					} // MEDIA-QUERY
					
					
								
			} // blockAddress
			
				
				
		
				
				
				
				
		// CHNEG THINGS FOR CASE-HEAD-OFFICE !!!!!		
		.blockRow {
			// NOT., add un poco de margin bottom, pero menos que entre items ..ergo 1 space, y entre items subir a 2 o 1,5..
			
			// HACKY
			span {display: none;}
			
			font-size: $fs_l; // TEST
			line-height: 1.4em; // TEST
			
			
			.row {
				// 4 rows
				@extend .clearfix;
				
				// display: flex;
				}
			
			// can use flex, css columns, etc etec etc !!!!
			
			.col {
				float: left;
				// width: 25%; 
				
				width: 23.5%;
				margin-right: 2%;
				&:nth-of-type(4n) {margin-right: 0px;}
				
				// border: 1px solid red; // DEV
				} // col
				
			// add margins!!!
			
			@media only screen and (max-width: $bp_m) {


			.row {
				margin-bottom: $spacing05; // TEST
				&:last-of-type {margin-bottom: 0px;}
				
				// FLEX
				// https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Flexible_Box_Layout/Ordering_Flex_Items
				// display: flex;
				} // row
				
			.col {
				width: 49%; 
				&:nth-of-type(2n) {margin-right: 0px;}
				// FLEX
				/*
				&:nth-of-type(1) { order: 1; }
        &:nth-of-type(2) { order: 3; }
        &:nth-of-type(3) { order: 2; }
        &:nth-of-type(4) { order: 4; }
				*/
				// https://www.freecodecamp.org/news/flexboxs-flex-basis-explained-83d1a01413b7/
				// flex-basis: 50%; 
					
					
				// HACKY 
				&:nth-of-type(2),
				&:nth-of-type(4) {display: none;}
				
				&:nth-of-type(3) {margin-right: 0px;}
				
				&:nth-of-type(3) {text-align: right;}
												
				} // col



				// HACKY
				span {display: block;}
				
				
				} // MEDIA-QUERY	
			
			@media only screen and (max-width: $bp_s) {

				.col {
					@include fullwidth;
					margin-right: 0%;	
						
					&:nth-of-type(3) {text-align: left;} // RESET
																								
					} // col

				} // MEDIA-QUERY	
				
				
			} // blockRow
			
									
		} // titleMain
	
	
	} // w_contactPage
	
	
	




