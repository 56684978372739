/* CSS Document - SASS */
/* Front-End */




// MATERIAL DESIGN COOR PICKER TOOLS !!!!!!!!!!!!!!!!!!!!!!!!!!!!

/* 
 * SASS - VARIABLES - Colors 
 */
$c_primary: #999; // FF5722
$c_primary_dark: #E64A19; 
$c_primary_light: #FFCCBC; 

$c_accent: #03A9F4;

$c_white: #fff; /* white */
$c_text: #212121;
$c_text_2: #727272;
$c_divider: #B6B6B6;



$c_black: #000; /* white */

$c_bg_gray: #626365; // footer, etc 

$c_bg_yellow: #FED100; // Sectors (refernces box)



/* 
 * VARIABLES - Font size 
 */
$fs_xxl: 34px;
$fs_xl: 28px;
$fs_l: 20px;
$fs_m: 16px;
// $fs_s: 12px;
$fs_s: 14px;



/* 
 * FALLBACKS using Sass
 */
 
 

/* CREATE MIXIN for rem fontsize and px fallback solution */

/* FALLBACK for background opacity */
	#asfgasafasf {
		background: #ccc; /* Fall-back for brosers that don't support opacity */
		background: rgba(204, 204, 204, 0.85);

	}



// create mixin - CSS_FALLBACK_tricks - LINK - the RGB fallback color only works when using shorthand. 
// http://css-tricks.com/ie-background-rgb-bug/



























