/*
 * Author: Carl Johansson @carlwebdev
 * Project: cowo-Boilerplate
 * File: Page Post (Reference & News Article)
 */
 
 
/* PAGE: SINGLE.PHP (BOTH FOR SINGLE NEWS & FOR SINGLE CLIENT !!)
 * MODULE: ....
 */		
 
section.w_PostPage { // USED IN: References(main) aka list, for now ...
	// border: 10px solid green; 
	
	// background-color: #A8A9AB; // TEMP, EXTRACTED FORM FILE  -SHOULD BE SASSIFIED IF PSSIBEL..
	// padding: $spacing; // TEST
	// border: 1px solid black; // DEV TEMP


	margin-bottom: $spacing4; // test
	
	@extend .clearfix;
	
	
	
		// flex etc !!!!
	
		.col {
			float: left;
			width: 49%; // TEMP
			// border: 3px solid blue; 
			margin-right: 2%; 
			&:nth-of-type(2n) {margin-right: 0%;} // duh, solo hay 2...
			margin-bottom: 2%; 
			&:last-of-type {margin-bottom: 0%;} // SIMPLE PRECAUTION FOR SINGLE COLUMN SCENARIO
			
			
			
			
			// RWD - THIS WILL BE TRICKY !!! NAYBE USE FLEX TO FIX IT UP, OR HIDE / SHOW LAYERS... 


			} // col	
	
	
	
		.image {
			// BG IMG
			@include bg_img;
			@include ar_4_3; // TEST, creo que ok
			// img {@include img;}
			} // image		
			
			

			
		
		
		.clientSpecs {
			// margin-top: $spacing05; // TEST
			margin-top: $spacing; // TEST
			@include f_main_bold;
			
			.line1 {

				} // line1
				
			.line2 {
				margin-top: $spacing; // TEST HERE, IN CASE NO EXISTS
				} // line2	
							
			} // clientSpecs
		
		
		.socialBox {
			margin-top: $spacing05; // TEST
			
			// border: 1px solid red; // DEV
			@extend .clearfix;
			
			ul {
				// @extend .clearfix;
				float: right; // !!!
				}
			
			li {
				// float: left;
				display: inline-block;
				vertical-align:middle;
				
				// margin-right: $spacing05; // TEST
				// margin-right: $spacing025; // TEST
				&:last-of-type {margin-right: 0px;}
				
				
				&:first-of-type {margin-right: $spacing025;}
				
				
				img {
					@include img;
					width: 32px; // TEST
					}
				
				// 1 
				@include f_main_bold;
				
				} // li
			
			
			
			
			
			
			
			} // socialBox 
		
			
			
					
		.info {		
			// @include paddingv($spacing2); // TEST // OJO SAME HEIGHT TRICKL DE JS !!!!!!!!!!!!!!!!
			// padding-top: $spacing; 
			// padding-bottom: $spacing2;
			@include paddingh($spacing); // TEST
			
/*			.title {
				// text-transform: uppercase; // PRECAUTION
				font-size: $fs_l;
				// font-size: $fs_xl; // TEST
				// margin-bottom: $spacing; // TEST
				} // title*/

			// USE THE the_content FIELD FOR THE NAME SHOWN
			.title { // SAME AS IN INTRO TEXT THINGE - COPIED FORM THERE, CREAT SASS MIXING !!!!!!!!!!!!!!!!!!
				text-transform: uppercase; // PRECAUTION
				// font-size: $fs_l; // TEST
				font-size: $fs_xl; // TEST - BECAUSE IT'S NOT QUITE THE SAME !!!
				@include f_main_bold;
				margin-bottom: $spacing; // TEST - same separation que entre p's
				}
			
			.text { // SAME AS IN INTRO TEXT THINGE - COPIED FORM THERE, CREAT SASS MIXING !!!!!!!!!!!!!!!!!!
				// text-transform: uppercase; // PRECAUTION
				font-size: $fs_l; // TEST
				// font-size: $fs_xl; // TEST - BECAUSE IT'S NOT QUITE THE SAME !!!
				// font-weight: bold; // TEMP WHLE I ADD THE FONT !!!
				// margin-bottom: $spacing; // TEST - same separation que entre p's	
				// border: 1px solid red; // DEV
				
				
				// headers !!!!!!
				// anchors !!!!
				
				h1, h2, h3, h4, h5, h6 {
					@include f_main_bold;
					}

				a {
					text-decoration: underline;
					&:hover {text-decoration: none;}
					}	
				
				
				ul {
					margin-bottom: $spacing; // test
					
					
					margin-top: $spacing; // test - precaution when teh list comes after the last p tag ... hmm 
					}
				
				li { // MIUST TEST THIS ONLINE !!!!!!!!!!!!!!!!!!!
					list-style: disc;
					// margin-left: $spacing075; // test
					margin-left: $spacing; // test
					}
				
				
				
					
				} // text
				
			} // info
			
			
			
			
		@media only screen and (max-width: $bp_m) {
			
			.col {
				@include fullwidth; 
				margin-right: 0%; 
				} // col	
						
			.image {
				// BG IMG
				// @include ar_16_9; // TEST, PARA MENOS ALTURA, YA QUE LAS IMAGENES SON BULLSHIT ANYWAYS.
				// @include ar_cinemascope; // TEST
				// border: 5px solid black; // DEV
				} // image	
				
			.info {		
				@include paddingh(0px); // TEST
				} // info	
							
			} // MEDIA-QUERY	
			
			
	
	} // w_PostPage
	
	
	
