/*
 * Author: Carl Johansson @carlwebdev
 * Project: cowo-Boilerplate
 * File: Page Home
 */
 
 
/* PAGE: SECTOR (SINLGE) + SERVICE (SINGLE) = THEREFORE RENAMED AS genericIntroText
 * MODULE: INTRO TEXT  -SECTOR INTRO TEXT 
 */		
 
section.genericIntroText { // WAS sectorIntroText
	// border: 10px solid green; 
	
	// @include paddingv($spacing2); // TEST
	@include paddingv($spacing3); // TEST
	padding-top: $spacing4;
	
	
	// ACF
	.title {
		text-transform: uppercase; // PRECAUTION
		// font-size: $fs_l; // TEST
		font-size: $fs_xl; // TEST
		// font-weight: bold; // TEMP WHLE I ADD THE FONT !!!
		@include f_main_bold;
		margin-bottom: $spacing; // TEST - same separation que entre p's
		// border: 1px solid red; 
		
		// 2 MODS FOR ABOUT PAGE
		&.case-about {
			// font-size: $fs_xl; // TEST
			font-size: $fs_xxl; // TEST - ups !!! didsnt chedck what it did
			}
		&.case-about-centered {
			// font-size: $fs_xl; // TEST
			font-size: $fs_xxl; // TEST
			text-align: center;
			}
			
		} // title
	
	.text {
		// font-size: $fs_m; // TEST
		font-size: $fs_l; // TEST - maybe here too, big !!
		
		@extend .clearfix;
		
		&.case-about-centered {
			// font-size: $fs_l; // TEST
			// font-size: $fs_xl; // TEST
			text-align: center;
			
			// WIDTH
			@include centered;
			max-width: 500px; // TEST
			}
			
					
			.col {
				float: left;
				// width: 50%;
				// width: 49%
				width: 48%;
				// margin-right: 2%; // TEST
				margin-right: 4%; // TEST
				&:nth-of-type(2n) {margin-right: 0%;} // TOO VERBOSE, BUT FOR PRECAUTION
				
				// margin..
				// border: 1px solid red; // dev
				
				// RWD	
				@media only screen and (max-width: $bp_m) { 
					@include fullwidth; 
					} // MEDIA-QUERY	
				
				} // col
			
			
			
		
		/*
		// -webkit-column-count: 3; // Old Chrome, Safari and Opera 
		// -moz-column-count: 3; // Old Firefox
		// column-count: 3;
		
		// @include columns(2); // IT WORKED TAL CUAL !!!!! :-)		
		@include columns(2); // TESTING TO ADD COLUMN GAP IN THIS SHORTHAND MODE
		// @include columns(100px); // TEST - THIS JUST DOES THE WIDTH OF EACH COLÑIUMN...
		
	  -webkit-column-gap: $spacing2; // Chrome, Safari, Opera 
  	-moz-column-gap: $spacing2; // Firefox
  	column-gap: $spacing2;
			
		@media only screen and (max-width: $bp_m) { 
			@include columns(1);
			} // MEDIA-QUERY	
		*/
		
			
					
		} // TEXT
	
	
	} // genericIntroText 
	
	
	
	
	
	
	
	
	
	
	
	
	 
/* PAGE: SECTORS(), ...
 * MODULE: LIST BOXES 
 */		
	
section.sectorListBoxes {
	// border: 10px solid green; 

	.gridListBoxes  {
		// overflow: hidden;
		@extend .clearfix; // TEST // WORKS
		
		.col {
			float: left;
			width: 50%;
			
			// RWD	
			@media only screen and (max-width: $bp_m) { 
				@include fullwidth; 
				} // MEDIA-QUERY	
			
			} // col
		
		.item {
			// border: 3px solid black; // DEV
	
			// JAVASCRIPT CONTROLS THE SAME HEIGHT OF THE 2 BOXES.... ALSO TO BE APPLIED TO OTHER SCENARIOS IE IN THE HOMEPAGE ETC !!!!
			// BG COLORS MUST BE APPLIED TO THIS EQUALLED HEIGHT FAMILY OF ELEMNETS, NBOT TO THE INTERIOR !!!
			// YELLOW ABOLSUTE & BOTTOM ...  ?? NOT SO GOOD IDEA
	
			&.bg_1 {
				background-color: #EAE0DE;
				color: $c_black;
				}
			&.bg_2 {
				background-color: #9A9B9D; // #9A9B9D , similar to bg_3 de la homepage !!!
				color: $c_white;
				}		
			
			// TEST - but should learn flex & grid for this !!!! - not good.... it must act like asticky footer of sorts ...
			// position: relative;
					
					
			.info {
				// padding: $spacing2; // TEST DEV
				padding: $spacing3; // TEST DEV
				
				&.case-only-list {
					// try to center teh list a little... 
					border: 1px solid red;
					
					
					} // case
				

				.title {
					text-transform: uppercase; // PRECAUTION
					// font-size: $fs_l;		
					font-size: $fs_xl;				
					@include f_main_bold;
					margin-bottom: $spacing; // TEST
					
					
					// border: 1px solid red;
					
					
					} // line1
					
				.list {
					// font-size: $fs_m;
					font-size: $fs_l;	
					
					ul {}
					
					li {
						list-style: disc; // TEST
						margin-left: $spacing075; // TEST
						}
					
					} // list	
	
				} // info
				
				
			} // item
			
			
			

			
		
			
		}	// gridListBoxes
		
		





	} // sectorListBoxes 
	


			// TAKEN OUTSIEDE, AS ITS ALSO USED IN SERVIC PAGE !!!


			.boxYellow { // IMPROVE THE LAYOUT ACCORDING TO THE DESIGN !!!!!!!!!!!!!!!!!!!
				background-color: $c_bg_yellow;
				font-size: $fs_m;
				
				
				
				// TEST - but should learn flex & grid for this !!!!
				// position: absolute; // PARENT DIV REQUIRES position: relatie; 
				// width: 100%;
				// bottom: 0px;
				
				
				
				span {
					text-transform: uppercase; // PRECAUTION
					@include f_main_bold;
					font-size: $fs_l;
					}
				
				a {
					display: block; // for full width
					// padding: $spacing3; // TEST DEV
					padding: $spacing2; // TEST DEV
					@include paddingv($spacing); // TEST
					
					background-color: $c_bg_yellow;
					@include transitionButton;
					&:hover {
						background-color: darken($c_bg_yellow, 2%); // DEV TEMP CARL STYLE
						}
						
					} // a
				
				
				
				
				
				} // boxYellow
				
				
				
	
	
	
/* PAGE: SECTORS(), ...
 * MODULE: RANDOM IMAGE
 */		
 
section.sectorRandomImg { 
	// border: 3px solid red; 
	// height: 100vh; // TEST
	height: 70vh; // TEST
	// BG IMG
	@include bg_img;
	// img {@include img;}

} // sectorRandomImg



 
 
 			