/*
 * File: Global
 */
 
/*
Susy: Responsive grids for Compass
http://susy.oddbird.net/
susy might not work as such in compass / scout / sass, unless manually downloaded and refernced to susy.
now testing zengrids.com , as it seams to have support inside scout / for compass.
http://zengrids.com/
*/

/* COMPASS */
@import "compass/css3";

/* BASE */
@import "base/reset";
@import "base/sass_style";
@import "base/sass_layout";
@import "base/typography";
@import "base/typopgraphy_htmleditor";
@import "base/buttons";  /* MUST BE CALLED BEFORE FORMS, AS CERTAIN FOMR ELEMENTS CALL BUTOTN STYLES */

// GROUP - FORMS
/*
@import "base/form_layout";
@import "base/form_elements";
@import "base/form_filebutton";
*/
@import "base/layout";

/* COMPONENTS */
@import "components/header";
@import "components/wp_nav";
@import "components/footer";

@import "components/cookies"; // wp !!!

@import "components/page_home";
@import "components/page_sectors"; // this is actually page for 1 sector, aka PAGE_SECTOR - MUST CHANGE THE TEMPALTE NAME & ASSIGNATION, ADN WP TITLE, AND SASS PARTIAL NAME & INTERNAL CSS CLASES, AND ALSO HTML !!!
@import "components/page_services"; // ALL SERVICES - SERVICES MAIN PAGE
@import "components/page_service"; // 1 INDIVISUAL SERVICE

@import "components/page_references"; // AKA CLIENTS
@import "components/page_news";
@import "components/page_post"; // FOR BOTH SINGLE REFERENCE AND SINGLE NEWS
@import "components/page_about"; // STARTING THIS
@import "components/page_contact";


@import "components/slick";

/* MODULES */
@import "modules/lightboxme";

@import "modules/jquery.mmenu.all"; /* Mmenu - Side menu */

@import "modules/headroom"; 